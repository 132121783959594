import React from 'react'
import NavbarOtherComponent from './../Component/NavbarOtherPages/NavbarOtherPages'
import CookiesPolicyComponent from './../Component/CookiesPolicy/CookiesPolicy'


function cookiesPolicies() {
    return (
        <div>
            <NavbarOtherComponent />
            <CookiesPolicyComponent />
            
        </div>
    )
}

export default cookiesPolicies
