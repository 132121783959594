import React from 'react'
import './whoweare.css'

function whoweare() {
    return (
          <>
          <video autoPlay muted loop className="firstvide-background">
               <source src="/finalvideos/1.mp4" type="video/mp4"  />
          </video>
     
          <div className="firstoverlay"></div>
   
          <div className="firstlayertxt">
          
               <div className="firstnumber">01</div>

               <div className="main_heading"> WHO WE ARE  </div>

               <div className="first_border_bottom_empty_div animatefadindown"> </div>

               <div className="firstParagrapgtxt">
               We bring market disruption with our Innovative AI products driven by <span >  MACHINE LEARNING AND DEEP LEARNING FOR AEC INDUSTRY </span>
               </div>

               <div className="row firstrow">
                    <div className="col-md-6 col-lg-4 cols1">
                         <p className="txt">
                         Driven by research analytics for user centric approach in construction industry​
                         </p>
                    </div>

                    <div className="col-md-6 col-lg-4 cols1">
                         <p className="txt">
                         Tranforming machine learning and deep learning into innovative products
                         </p>
                    </div>

                    <div className="col-md-6 col-lg-4 cols1">
                         <p className="txt">
                         Creating barrier free workflow process by adopting digital twins
                         </p>
                    </div>
               </div>

          </div>
     </>
 
    )
}

export default whoweare
