import React,{Component} from 'react'
import './TermsComponent.css'

class TermsComponent extends Component {
  
   render(){
        return (
            <div id="privacy" className='privacystyle'>
                <div className='heading'>Terms and Conditions</div> 
                <div className='overPad'>
                    <div className='fontStyle'>We are dedicated to your data security</div>
                    <div className='pad20'> The Privacy Policy below describes issues related to the use of the Jupiter brothers data science Private Limited application, including issues related to personal data protection (in accordance with GDPR) and cookies.</div>
                    <div className='pad20'></div>
                    <div className='pad20font24'>Cookies on the Jupiter brothers data science Private Limited website</div>
                    <div className='pad20'> Jupiter brothers data science Private Limited website saves cookies on visitors' devices. Jupiter brothers data science Private Limited uses two types of cookies - session and persistent. Session files are stored only until a visitor leaves the Jupiter brothers data science Private Limited website (by going to a different webpage, logging out or closing the browser). Persistent files are stored on a user’s device until they are removed by the user or for a period of time specified in their settings.</div>
                    <div className='pad20font24'> Jupiter brothers data science Private Limited uses the following cookies:</div>
                    <div className='pad20'>Necessary for proper activity of the Jupiter brothers data science Private Limited.com websites and Jupiter brothers data science Private Limited Teams services:
                        Assets.Jupiter brothers data science Private Limited.com (to track traffic on the Jupiter brothers data science Private Limited websites for the purpose of operating the affiliate program and for internal statistics used to improve the service);
                        Geoip.Jupiter brothers data science Private Limited.com (to determine the country of residence of the visitor in order to show our pricing offer in the local currency); and,
                        A general cookie (which enables us to set or save the language of the visitor).</div>
                    <div className='pad20font24'>Optional cookies:</div>
                    <div className='pad20'>  Marketing cookies - collecting data for statistics that enable us to learn Users' preferences and behaviors. The analysis of these statistics is anonymous. Jupiter brothers data science Private Limited uses Google Tag Manager to accomplish this. Each visitor can change their browser settings and disable cookies at any time or ask to obtain information each time they are placed on their device. To disable or limit the use of cookies on your computer, please read the information available on the manufacturer’s website of your browser.</div>
                    <div className='pad20font24'> Our address:</div>
                    <div className='redTop'> Jupiter Brothers Data Science Pvt limited</div>
                    <div className='pad2'> No:2/51, Maraneri Village, Maraneri Post</div>
                    <div className='pad2'> Sivakasi, Virudhunagar District</div>
                    <div className='padTopBot'> Tamil Nadu, India, Pin-626124 CIN:U72900TN2020PTC140261</div>
                </div>
            </div>
        );
    }
}

export default TermsComponent;