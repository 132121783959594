import React from 'react'
import './ourfuture.css'

function ourfuture() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background">
          <source src="/finalvideos/6.mp4" type="video/mp4"  />
        </video>
  
        <div className="Sixthcomponent sixthlayertxt">
  
          <div className="HeaderNumber sixthfadindown">06</div>
  
          <div className="Header_definition sixthComponentfadeinLeft">Our future is influenced by our past</div>
  
          <div className="border_bottom_empty_div sixthfadindown"></div>
  
          <div className="Paragraph_txt sixthfadindown">We guide clients with data driven strategies by <span class="sixthsubparagraph_txt" >Machine & Deep learning for enriched business </span>&nbsp;solutions where we</div>
         
          <div className="row rows_cols1 sixthfadindown">
            <div className="col-md-6 col-lg-4 cols1">
              <p className="txt">Elevate human potential through technology​​</p>
            </div>
            <div className="col-md-6 col-lg-4 cols1">
              <p className="txt">Providing Equal opportunities for ACE professionals in accesing Technology.</p>
            </div>
            <div className="col-md-6 col-lg-4 cols1">
              <p className="txt">Create opportunities for colloborative for Digital transformation</p>
            </div>
            <div className="col-md-6 col-lg-4 cols1">
              <p className="txt">Enable local companies to achieve global standards</p>
            </div>
            <div className="col-md-6 col-lg-4 cols1"> 
              <p className="txt">Enrich ypung minds with latest trends and technology in AEC industry</p>
            </div>
            <div className="col-md-6 col-lg-4 cols1">
              <p className="txt">We guide clients with data driven strategies by Machine</p>
            </div>
          </div>
        
        </div>
      </>
    )
}

export default ourfuture
