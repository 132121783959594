import React from 'react'
import './NavbarOtherPages.css'
import {Link} from 'react-router-dom'


function NavbarOtherPages() {
    return (
      <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top menu    chandrika" style={{marginLeft:"50px",paddingLeft:"0px"}}>
          <Link class="navbar-brand " to=""><img src="/images/logoBlack.png" class="img-fluid logo_Img"  alt=""/></Link>
          <button class="navbar-toggler navbar-toggle-btn" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon toggle-icon-color"></span>
          </button>
        
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <Link class="nav-link " to="/about">
                    <span class="Navbar_span" style={{color:"black"}}>
                    About
                    </span>
                    </Link>
               </li>
             
               <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown" style={{color:"black"}}>
                    Products
                    </a>
                    <div class="dropdown-menu">
                    <Link to={{ pathname: "https://app.welldo.expert/" }} target="_blank"  class="dropdown-item" ><img src="/new WELLDO logo.png" className="img-fluid" style={{width:"80px",height:"auto"}}/></Link>
                     
                    </div>
                  </li>
               <li class="nav-item">
                 <Link class="nav-link "  to="/blog">
                 <span class="Navbar_span" style={{color:"black",borderBottom:"3px solid #000",paddingBottom:"10px !important",}}>
                 Blog
                    </span>
                 </Link>
                 
              </li>
          </ul>

    </div>
    </nav>
 
    </div>
    )
}

export default NavbarOtherPages
