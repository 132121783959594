import React, { Component } from 'react'
import './NavbarComponent.css'
import {Link} from "react-router-dom"


  class NavbarComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          currentPath:window.location.pathname.split('/')[1]
        }
    }

    render() {
      return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top menu common_padding" style={{marginLeft:"50px",paddingLeft:"0px"}}>
          <Link className="navbar-brand" to="">
            <div >
            <img src="/images/jplogowhite.png" class="img-fluid logo_Img" />
              {/* <img src={match === "/" ? "/images/jplogowhite.png" : "/images/logoBlack.png" } className="img-fluid"  alt=""/>  */}
              {/* <img src={locationprops.path === "/" ? "/images/jplogowhite.png" : "/images/logoBlack.png" } className="img-fluid" /> */}
              {/* <img src="/images/jplogowhite.png" class="img-fluid logo_Img" /> */}
            </div>
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
        
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link className="nav-link active" to="/about">
                      <span className={this.state.currentPath=='' || this.state.currentPath=='about'?'Navbar_span':'default_span'}>About</span>
                    </Link>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Products</a>
                    <div className="dropdown-menu">
                    {/* <Link to={{ pathname: "https://welldo.expert/" }} target="_blank"  className="dropdown-item" ><img src="/images/new WELLDO logo.png" className="img-fluid" style={{width:"80px",height:"auto"}}/></Link> */}
                    <Link to={{ pathname: "https://enthiran.jupiterbrothers.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">Enthiran MEP</div></Link>
                    <Link to={{ pathname: "https://vpcbim.jupiterbrothers.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">Enthiran BIM</div></Link>
                    <Link to={{ pathname: "https://inakkam.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">Inakkam</div></Link>
                    <Link to={{ pathname: "https://leedbondhu.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">LeedBondhu</div></Link>
                    <Link to={{ pathname: "https://welldo.expert/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">WELLDO 2.0</div></Link>
                    {/* <Link to={{ pathname: "https://thogupu.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">Enthiran</div></Link> */}
                    {/* <Link to={{ pathname: "https://thogupu.com/" }} target="_blank"  className="dropdown-item" ><div className="dropdown_name tool_names">Inakkam</div></Link> */}
                      
                    </div>
                  </li>
                {/* <li className="nav-item">
                  <Link className="nav-link " to={{ pathname: "https://jupiterbrother.blogspot.com/2021/11/in-times-of-crisis-smart-building.html" }}>Blog</Link>
              </li> */}
              <li className="nav-item">
              <a className="nav-link" href={"https://jupiterbrothersjb.blogspot.com/2021/11/smart-buildings-for-healthy-work-places.html"} target="_blank">Blog</a>
              </li>
          </ul>

    </div>
        </nav>
      )
    }
}

export default NavbarComponent
