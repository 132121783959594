import React from 'react'
import Navbar from './../Component/NavbarComponent/NavbarComponent'
import Footer from './../Component/FooterComponent/FooterComponent'
import Privacy from './../Component/Privacy/PrivacyComponent'

function PrivacyPage() {
    return (
        <div>
             <Navbar />
            <Privacy />
            <Footer />
        </div>
    )
}

export default PrivacyPage