import React from 'react'
import NavbarOtherComponent from '../../Component/NavbarOtherPages/NavbarOtherPages'
import BlogDefinition from '../../Component/BlogDefinitionPage/BlogDefinitionPage'
import './contentblogPage.css'


function contentblogPage() {
    return (
        <div>
            <NavbarOtherComponent />
            <BlogDefinition />
            
         
            
        </div>
    )
}

export default contentblogPage
