import React from 'react'
import './dataeverywhere.css'

function dataeverywhere() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background">
            <source src="/finalvideos/5.mp4" type="video/mp4"  />
        </video>

        <div className="fifthlayertxt">
            <div className="fifthHeaderNumber Fifthfadindown">05</div>
            <div className="fifthHeader_definition FifthComponentfadein" >DATA IS EVERYWHERE</div>
            <div className="fifthborder_bottom_empty_div Fifthfadindown"></div>
            <div className="fifthParagraph_txt Fifthfadindown">
                <p>Knowing what to do with it is the key to our future
                We are creating 
                <span data-aos="flip-up" className="subparagraph_txt"> DATA DRIVEN DECISION MAKING TOOLS</span>
                </p>
            </div>  
            <div className="row Fifththrow  Fifthfadindown">
                <div className="col-lg-12  cols1">
                    <p className="txt1">Collborative intelligence is the key. Teamwork,leadership,creativity and social skills from the Human Intelligence and speed,Scalability with quantitative and quantitative capability from the machine will be guiding us to the future.</p>
                </div>
            </div>
        </div>

    </>
    )
}

export default dataeverywhere
