import React from 'react'
import './breakingfree.css'

function wearebreakingfree_slide() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background">
           <source src="/finalvideos/4.mp4" type="video/mp4"  />
        </video>
        
        <div className="Fourthoverlay"></div>

        <div className=" fourthlayertxt">
           <div className="fourthHeaderNumber Fourthfadindown">04</div>
           <div className="fourthHeader_definition FourthComponentfadein" >WE ARE BREAKING FREE !​</div>
           <div className="fourthborder_bottom_empty_div Fourthfadindown" ></div>
           <div className="fourthParagraph_txt Fourthfadindown">
              Creating strategies to bridge the gap in between AEC tools that are operating in silos and to <span>BRING BARRIER FREE WORKFLOW PROCESS</span>​
           </div>

           <div className="row Fourthrow Fourthfadindown">
           <div className="col-md-6  cols1">
              <p className="txt1">Enabling organizations to Digitally Mature and help it to reach its Larger potential.</p>
           </div>
           <div className="col-md-6  cols1">
              <p className="txt1">From experience and leader-driven decision making to data-driven decision making for sustainable Growth</p>
           </div>
           </div>
        </div>
     </>
    )
}

export default wearebreakingfree_slide
