import React from 'react'
import './houston.css'

function houston() {
    return (
        <>
        <video autoPlay muted loop className="firstvide-background">
            <source src="/finalvideos/2.mp4" type="video/mp4"  />
        </video> 

        <div className="Secondoverlay"></div>

        <div className="Secondlayertxt">

            <div className="HeaderNumber secondnumber animatefadindown" > 02</div>

            <div className="Header_definition secondparagraph1 sixthComponentfadein" > Houston ! <span>We have a solution</span></div>

            <div className="second_border_bottom_empty_div animatefadindown"> </div>

            <div className="second_Paragraph_txt animatefadindown" >
                Empowering construction industry by ML I DL where artificial intelligence drives
                <p> <span data-aos="flip-up" className="subparagraph_txt"> DATA DRIVEN DECISION MAKING TOOLS</span> </p>
            </div>
            
            <div className="row secondrow animatefadindown">

                <div className="col-md-6  cols1">
                    <p className="txt">From rigid and risk-influenced portfolio to agile,experimental and adaptable future.</p>
                </div>

                <div className="col-md-6  cols1">
                    <p className="txt">Creating the AI-Powered Organization where we get transformed from siloed work to inter disciplinary collaboration. </p>
                </div>
            </div>
            
        </div>
    </>
   
   )
}

export default houston
