import React, { Component } from 'react'
import './ToConnectComponent.css'
import axios from 'axios'

class ToConnectComponent extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
             email:" ",
             sent:false,
             toConnect:false,
             hideSubmit:false,
        }
    }
  

    //handle event
    onhandleEmail=(e)=>{
        this.setState({
            email:e.target.value
           
        })
        console.log(e)
    }


    formSubmit=(e)=>{
        e.preventDefault();
        
        if(this.state.hideSubmit==true){
            this.setState({hideSubmit:false})
        }
        else{
            this.setState({hideSubmit:true})
        }

        axios.get('https://backend.welldo.expert/well/toconnect/'+this.state.email)
        .then(function (response) { console.log(response); })
        .catch(function (error) { console.log(error);  })
        .then(function () {
            document.getElementById('email_id').value='';
        });  
    }

    connectChange=(e)=>{
        e.preventDefault();
   
        if(this.state.toConnect==true){
            this.setState({toConnect:false})
        }
        else{
            this.setState({toConnect:true})
        }
    }
  
    //for reseting initial data

    // resetForm=()=>{
    //     this.setState({
    //         email:" "
    //     })

    //     setTimeout(()=>{
    //         this.setState({
    //             sent:false,
    //         })
    //     })
    // }


    render() {
       
        return (
            <>
                <div class="row connectdesign">
                    <div  class={this.state.toConnect?'connectdesign_left':'display_none'}>
                        <div className={this.state.hideSubmit?'display_none':''}>
                            <h1 className={this.state.hideSubmit?'display_none':'connecttxt'}>Enter your mail id to get free newsletters and further updates of our tools.</h1>
                            <p><input type="text" className={this.state.hideSubmit?'display_none':'textboxconnect'} placeholder="Enter your email id" id="email_id" value={this.state.name} onChange={this.onhandleEmail} /></p>
                            <button className="connectbtn" onClick={this.formSubmit}>Submit</button>
                        </div>
                        <div className={this.state.hideSubmit?'yay_div':'display_none'} >
                            <img src="/yay.svg" />
                            <h1 className="connecttxt" style={{textAlign:"center"}}>Thanks for Subscribing to our Newsletter Please check your mail.</h1>
                        </div>
                    </div>
                    <div onClick={this.connectChange}> <img src={this.state.toConnect?'/connect_whitebg.svg':'/connect_redbg.svg'}  className="red_text"  /></div>
                </div>
            </>
        )   
    }
}

export default ToConnectComponent
