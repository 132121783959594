import React from 'react'
import './FooterComponent.css'
import { Link } from "react-router-dom"

function FooterComponent() {
    return (
       <div className="footer_bottom">
            <div className="copy_right">Copyright@2020</div>
            <div className="termsandpolicy">
                <div className='terms'>
                    <Link  to="/terms" className="link" >Terms</Link>
                </div>
                <div className='terms'> 
                    <Link  to="/privacy" className="link" >Privacy</Link>
                </div>
            </div>
        </div> 
    )
}

export default FooterComponent
