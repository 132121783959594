import React from 'react'
import './ErrorPageComponent.css'

function ErrorPageComponent() {
    return (
          <div className="errorPagediv">
              <div className="errorImage">
                  <img src="/images/19.png" className="img-fluid"  alt=""/>
              </div>
              <h1>Oops! We can’t find a page you are looking for</h1>
              <button className="btn">Back</button>

          </div>
    )
}

export default ErrorPageComponent
