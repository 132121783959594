import React from 'react'
import ErrorPageComponent from './../Component/ErrorPageComponent/ErrorPageComponent'
import Navbar from './../Component/NavbarOtherPages/NavbarOtherPages'

function errorPage() {
    return (
        <div>
            <Navbar />
            <ErrorPageComponent />
        </div>
    )
}

export default errorPage
