import React from 'react'
import './BlogRedesign.css'
import { Link} from "react-router-dom";

function BlogRedesign() {
    return (
        <div className="blog_element">
            <div className="container-fluid">
                {/* <div className="tags_hightlight">
                    <div className="title_name">TAGS:
                        <p>Artificial Intelligence ,</p>
                        <p>Machine Language ,</p>
                        <p>AI | ML</p>
                    </div>
                </div>   */}
                <div className="row">
                    <div className="col-md-5" style={{padding:0,margin:0}}>
                        <div >
                            <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content1.svg" style={{width:"100%"}} /></Link>
                            {/* <div  className="para_element" >
                                <div className="first_element">A WONDERFUL SERENITY HAS TAKEN POSSESSION OF MY ENTIRE SOUL.</div>
                                <div className="emyt"></div>
                                <div className="subPara_textblog">While the lovely valley teems with vapour around me, and the meridian sun strikes the upper surface of the impenetrable foliage.</div>
                                <div className="Load_More">
                                <Link className="Find_More" to="/pageContent1">Find More</Link>
                                </div>
                            </div> */}
                        </div>   
                        <div className="second_row" >
                            <div className="Second_element" >
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content2.svg" style={{width:"100%",height:"100%"}}/></Link>
                            </div>
                            <div className="Second_element1">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content3.svg" style={{width:"100%",height:"100%"}}/></Link>
                            </div>
                         

                        </div>
                    </div>
                    <div className="col-md-5" style={{padding:0,margin:0,border:"2px solid red"}}>
                       <div className="secondcol">
                           <div className="secondcol1_row">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content4.svg" style={{width:"100%",height:"100%"}}/></Link>
                           </div>
                           <div className="secondcol2_row">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content5.svg" style={{width:"100%",height:"100%"}}/></Link>
                           </div>
                           <div className="secondcol3_row">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content6.svg" style={{width:"100%",height:"100%"}}/></Link>
                           </div>
                       </div>
                       <div className="secondcol2">
                            <div className="secondcol4_row">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content7.svg" style={{width:"100%",height:"100%"}}/></Link>
                            </div>
                            <div className="secondcol5_row">
                                <Link className="Find_More" to="/pageContent1"><img src="/blog/blog_content8.svg" style={{width:"100%",height:"100%"}}/></Link>
                            </div>
                       </div>
                    </div>
                    <div className="col-md-2 sidebar" style={{color:"#000 !important"}}>
                        <div className="Tags">
                        <div className="Tag_title">TAGS</div>


                        <div className="Tag_item1">Artifical Intelligence</div>
                        <div className="Tag_items">
                        <div className="Tag_item2">ML</div>
                        <div className="Tag_item3">DL</div>
                        <div className="Tag_item4">AI | Ml</div>
                        </div>


                        <div className="Tag_item5">Artifical Intelligence</div>

                        <div className="Tag_items">
                        <div className="Tag_item6">AEC</div>
                        <div className="Tag_item7">Data Analysis</div>
                        </div>

                        <div className="Tag_items">
                        <div className="Tag_item8">ML</div>
                        <div className="Tag_item9">DL</div>
                        <div className="Tag_item10">AI | ML</div>
                        </div>

                        <div className="Tag_item11">Artificial Intelligence</div>

                        <div className="Tag_items">
                        <div className="Tag_item12">AEC</div>
                        <div className="Tag_item13" >Data Analysis</div>
                        </div>

                        <div className="Tag_items">
                        <div className="Tag_item14">ML</div>
                        <div className="Tag_item15">DL</div>
                        <div className="Tag_item16">AI | ML</div>
                        </div>
                        </div>
                        </div>

                </div>
            </div>
        </div>
    )
}

export default BlogRedesign
