import React from 'react'
import './whatwebelieve.css'

function whatwebelieve() {
    return (
    <>
        <video autoPlay muted loop className="firstvide-background">
           <source src="/finalvideos/7.mp4" type="video/mp4"  />
        </video>
        <div className="Seventhcomponent seventhlayertxt">
           <div className="HeaderNumber seventhfadindown ">07</div>
           <div className="Header_definition seventhComponentfadeinLeft " >What we believe​ !</div>
           <div className="seventhborderempty_div seventhfadindown "></div>
           <div className="row seventhelementparentdiv seventhfadindown ">
              <div className="col-md-6 col-lg-4">
                 <div className="seventhparagraphelement">
                    <h5>Vision</h5>
                    <p className="diana">To create compelling data driven digital products that enables AEC businesses and professionals to be future ready​.</p>
                 </div>
              </div>
              <div className="col-md-6 col-lg-4">
                 <div class="seventhparagraphelement">
                 <h5 >Mission</h5>
                 <p  className="diana">Empowering every AEC professionals to acquire world class knowledge using collaborative and innovative technologies.</p>
                 </div>
              </div>
              <div className="col-md-6 col-lg-4">
                 <div className="seventhparagraphelement">
                 <h5 className="values">Values</h5>
                 <p  className="diana">People, Integrity, Innovativeness, customer excellence and continuous ​Learning, technology for all.​</p>
                 </div>
              </div>
           </div>
        </div>
     </>
    )
}

export default whatwebelieve
