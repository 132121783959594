import React from 'react'
import './humantouch.css'

function humantouch() {
    return (
        <div>
        <video autoPlay muted loop className="firstvide-background">
          <source src="/finalvideos/3.mp4" type="video/mp4"  />
        </video>
        <div className="Thirdoverlay"></div>
        <div className="thirdlayertxt">
          <div className="thirdHeaderNumber thirdComponentfadein" >03</div>
  
          <div className="thid_Header_definition  thirdfadindown" > Human Touch + AI = <span>Our Future !</span>​ </div>
  
          <div className="third_border_bottom_empty_div thirdComponentfadein" > </div>
  
          <div className="third_Paragraph_txt thirdComponentfadein">
            Guided by Human centric approach and Artificial Intelligence, <span className="">WE TRANSFORM YOUR  BUSINESS TO BE FUTURE READY </span>
          </div>
  
          <div class="row Thirdrow  thirdComponentfadein">
            <div className="col-lg-12  cols1">
              <p className="txt1"> As Common sense and experience are not only the key factor to anticipate the risk and consequences of critical business choices,AI and ML Powered Solutions helps the businesses to make informed and effective decisions which will have a positive impact on growth of the Business.</p>
            </div> 
          </div>
        </div>
    
      </div>
    )
}

export default humantouch
