import React from 'react';
import './App.css';


import HomePage from './Pages/HomePage'
import ErrorPage from './Pages/404errorPage'
import CookiesPage from './Pages/cookiesPolicies'
import ContentBlogpage from './Pages/contentblogPage/contentblogPage'
import BlogPageRedesign from './Pages/BlogPagedesgin'
import ToConnectComponent from './Component/ToConnectComponent/ToConnectComponent'
import PrivacyPage from './Pages/PrivacyPage'
import TermsPage from './Pages/TermsPage'
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Switch,Route } from "react-router-dom";

class App extends React.Component {
  
   // fake authentication Promise
   authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }


  render(){
  
    return (
      <div className="App">
            <Switch>
                <Route path="/" component={HomePage} exact/>
                <Route path="/about" component={HomePage} />
                <Route path="/blog" component={BlogPageRedesign }/>
                <Route path="/errorPage" component={ErrorPage}/>
                <Route path="/CookiesPage" component={CookiesPage }/> 
                <Route path="/BlogPage" component={BlogPageRedesign }/>
                <Route path="/contentPage" component={ContentBlogpage} />
                <Route path="/pageContent1" component={ContentBlogpage} />
                <Route path="/pageContent2" component={ContentBlogpage} />
                <Route path="/pageContent3" component={ContentBlogpage} />
                <Route path="/pageContent4" component={ContentBlogpage} />
                <Route path="/pageContent5" component={ContentBlogpage} />
                <Route path="/pageContent6" component={ContentBlogpage} />
                <Route path="/pageContent7" component={ContentBlogpage} />
                <Route path="/pageContent8" component={ContentBlogpage} />
                <Route path="/privacy" component={PrivacyPage} />
                <Route path="/terms" component={TermsPage} />
            </Switch>
            <CookieConsent
                  location="bottom"
                  buttonText="Accept and close"
                  cookieName="myAwesomeCookieName2"
                  style={{ background: "rgba(0, 0, 0, 0.3)" }}
                  buttonStyle={{ color: "#fff", fontSize: "20px" ,background: "rgba(0, 0, 0, 0.5)",marginRight:"100px",padding:"10px"}}
                  expires={150}
                  >
                    <p class="firstdivs_1">
                      Our website uses cookies to improve your browsing experience. Please “Accept and Close” to consent. View our Privacy & Cookies Policy.
                    </p>
            </CookieConsent>
            <ToConnectComponent />
      </div>
    );

  }
}

export default App;



 
