import React from 'react'
import Navbar from './../Component/NavbarComponent/NavbarComponent'
import Footer from './../Component/FooterComponent/FooterComponent'
import Terms from './../Component/TermsComponent/TermsComponent'

function TermsPage() {
    return (
        <div>
             <Navbar />
            <Terms />
            <Footer />
        </div>
    )
}

export default TermsPage