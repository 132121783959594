import React from 'react'
import BlogPageDesgin from './../Component/BlogRedesign/BlogRedesign' 
import NavbarOtherComponent from './../Component/NavbarOtherPages/NavbarOtherPages'


function BlogPagedesgin() {
    return (
        <div>
            <NavbarOtherComponent />
            {/* <NavbarComponent /> */}
            <BlogPageDesgin />
            
        </div>
    )
}

export default BlogPagedesgin
