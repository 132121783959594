import React from 'react'
import './CookiesPolicy.css'

function CookiesPolicy() {
    return (
        <div className="cookies_topsection">
            <div className="header_topsection">
               <div className="revised_text">Last revised: Jan 01, 2021 </div>
               <div className="cookies_paratxt1">We use cookies on this website (the "Site") to operate and administer the Site, conduct analytics and improve the Site and your experience on it.</div>
               <div className="cookies_paratxt2">
                   This Cookie Policy explains what cookies, web beacons, tracking pixels, clear gifs and other similar tracking technologies (collectively "the Cookies") are and how Semrush Inc., and its subsidiaries and affiliates (collectively "SEMrush", "us" or "we") uses Cookies when you visit websites owned and operated by SEMrush.
                </div>
                <div className="cookies_paratxt3">This Cookie Policy forms part of our Privacy Policy, which sets out how and why we collect, store, use and share personal data generally, as well as your rights in relation to your personal data and details of how to contact us. Please read our Privacy Policy for information on these items. Capitalized terms used in this Cookie Policy but not defined herein have the meanings set forth in our Privacy Policy.</div>
                <div className="cookies_paratxt4">By choosing to use the Site after having been notified of our use of cookies in the ways described in this Cookie Policy, and, in applicable jurisdictions, through notice and unambiguous acknowledgement of your consent, you agree to such use.</div>
            </div>
          
            <div className="header_middlesection">
                <div className="headermiddle_topsectiontxt">What are Cookies?</div>
                <div className="headermiddle_subParagrapgh">Cookies are small text files that websites place and store on your computer, mobile phone or any other device that can access the Internet. These files are generally used to improve your experience browsing our site. </div>
                <div className="headermiddle_subParagrapgh1">Cookies do not contain any information that personally identifies you; it is a passive file which cannot collect information on your computer or spread computer virus or other harmful programs.</div>
                <div className="headermiddle_subParagrapgh2">Cookies do lots of different jobs, like letting you navigate between pages efficiently, remembering your preferences, and generally improving the user experience. Cookies may tell us, for example, whether you have visited our site before or whether you are a new visitor.</div>
                <div className="headermiddle_subParagrapgh3">When you revisit a site, it will respond in a more personalized way, remembering your preferences, providing faster page loading and so forth. There are different types of Cookies. Some Cookies come directly from our website (first party cookies). While other cookies may be placed and stored by third parties over which semrush and its affiliates have no control (third party cookies).</div>
                <div className="headermiddle_subParagrapgh4"> Cookies can remain on your computer or mobile device for different periods of time. Some cookies are "session cookies", meaning that they exist only while your browser is open. These are deleted automatically once you close your browser. Other cookies are "permanent cookies", meaning that they survive after your browser is closed. They can be used by websites to recognize your computer when you open your browser and browse the Internet again.</div>
                <div className="headermiddle_subParagrapgh5">We may also use web beacon or other similar cookie technologies. When a visitor accesses our site, a non-identifiable notice of that visit is generated which may be processed by us or by third parties. These web beacons usually work in conjunction with cookies. If you do not want your cookie information to be associated with your visits to these pages, you can set your browser to turn off cookies as described further below. If you turn off cookies, web beacon and other technologies will still detect your visit to our site; however, they will not be associated with information otherwise stored in cookies.</div>
               
                <div className="headermiddle_topsectiontxt">How do we use Cookies?</div>
                <div className="headermiddle_subParagrapgh6">We use Cookies to enhance your experience with us. Cookies give us an understanding for how our web service is being used and helps us with development and improvements. In addition, certain cookies provide us with statistical information about the use of our website, which helps us understand the behavior and preferences of the visitors in an aggregated manner. For instance, we can use these cookies to gain insight into how visitors use our website, and analyze what works and what does not, in order to continuously improve our website, and measure how effective our communication is. Cookies may further facilitate certain third-party applications such as social media plug-ins</div>
                <div className="headermiddle_subParagrapgh7">Cookies overall, allow you to browse and view our website, adapt the content or display of our services to your device to improve your navigation and optimize our website.</div>
               
                <div className="headermiddle_topsectiontxt">What types of Cookies do we use?</div>
                <div className="headermiddle_subParagrapgh8">We use different types of cookies.</div>
                <div className="headermiddle_subParagrapgh9">The types of cookies used by us in connection with the website can be classified into one of three categories, namely "essential website cookies", "functionality cookies", and "analytics and performance cookies". We have set out some further information about each category, and the purpose of the cookies we set below.</div>
                <div className="headermiddle_subParagrapgh10">Cookies are categorized by their origin as first party cookies or third party cookies. Cookies are also categorized as session cookies or persistent cookies, depending on the duration they stay on your device(s).</div>
               
                <div className="headermiddle_topsectiontxt">First-Party Cookies</div>
                <div className="headermiddle_subParagrapgh11">First-Party cookies are cookies set by us and used within our control. </div>
            
                <div className="headermiddle_topsectiontxt">Third-Party Cookies </div>
                <div className="headermiddle_subParagrapgh12">Third-Party cookies are set by someone other than SEMrush. Some of our web pages may for instance also contain content from other websites, which may set their own cookies when you access that content. Also, if you share a link to a webpage of ours, the service you share it on (e.g., Facebook, Twitter or LinkedIn) may set a cookie on your browser. You have the ability to turn them off in your browser settings.</div>
                <div className="headermiddle_subParagrapgh13">Please see the list of Third-party cookies we use.</div>


                <div className="headermiddle_topsectiontxt">Session Cookies</div>
                <div className="headermiddle_subParagrapgh14">Session cookies are temporary and remain on your device only as long as your browsing session lasts. They disappear from your computer or device when you close your browser.</div>

                <div className="headermiddle_topsectiontxt">Persistent Cookies</div>
                <div className="headermiddle_subParagrapgh15">Persistent cookies remain on your computer or device also after your browsing session has ended, and last for a time specified in the cookies. We use persistent cookies when we need to remember your preferences and actions for the next time you visit.</div>
               
                <div className="headermiddle_topsectiontxt">Cookies necessary for essential website purpose</div>
                <div className="headermiddle_subParagrapgh15">Necessary Cookies are cookies that ensure the proper functioning of the Website (e.g., cookies for login or registration). The information collected through these Cookies is not used for marketing purposes. Necessary cookies relate to the functionalities of our websites and allow us to improve the services that we offer to you, and therefore cannot be turned off.</div>
                <div className="headermiddle_subParagrapgh16">These cookies have a very short lifespan (the time of the session).</div>

                <div className="headermiddle_topsectiontxt">Functionality Cookies </div>
                <div className="headermiddle_subParagrapgh17">Functionality cookies record information about choices you have made and allow us to tailor the website to you. These cookies mean that when you continue to use or come back to the website, we can provide you with our services as you have asked for them to be provided. For example, these cookies allow us to:</div>
                <div className="headermiddle_subParagrapgh18">Save your location preference if you have set your location on the homepage in order to receive a localized information; Remember settings you have applied, such as layout, text size, preferences, and colors; Show you when you are logged in; and Store accessibility options.</div>
              
                <div className="headermiddle_topsectiontxt">Performance/Analytics Cookies</div>
              
                <div className="headermiddle_subParagrapgh19">We use performance/analytics cookies to analyze how the website is accessed, used, or is performing in order to provide you with a better user experience and to maintain, operate and continually improve the website. For example, these cookies allow us to:</div>
                <div className="headermiddle_subParagrapgh20">Better understand our website visitors so that we can improve how we present our content; Test different design ideas for particular pages, such as our homepage;Collect information about website visitors such as where they are located and what browsers they are using; Determine the number of unique users of the website;Improve the website by measuring any errors that occur; and Conduct research and diagnostics to improve product offerings. How to control or delete cookies</div>
                <div className="headermiddle_subParagrapgh21">If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser. Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</div>
                <div className="headermiddle_subParagrapgh22">Web browsers allow you to change your cookie settings. These settings can generally be found in the menu "Options" or "Preferences" of your web browser. In order to understand these settings better, the following links can be useful. In case you need additional information, you can consult the "Help" function in your web browser.</div>


                <div className="headermiddle_topsectiontxt">Find out how to adjust the settings for different browsers:</div>
                <div className="headermiddle_subParagrapgh23">Chrome</div>
                <div className="headermiddle_subParagrapgh24">Firefox</div>
                <div className="headermiddle_subParagrapgh25">Internet Explorer</div>
                <div className="headermiddle_subParagrapgh26">Opera</div>
                <div className="headermiddle_subParagrapgh27">Safari</div>
                <div className="headermiddle_subParagrapgh28">If you wish to find out more about removing or blocking cookies, we find this website quite helpful: http://www.aboutcookies.org/Default.aspx?page=2</div>
                <div className="headermiddle_subParagrapgh29">With new devices being created all the time, including tablets and phones it is not possible to list every browser for every device. The best advice is to consult the manufacturer's website for further instructions regarding cookies.</div>


                <div className="headermiddle_topsectiontxt">Cookies that have been set in the past</div>
                <div className="headermiddle_subParagrapgh30">If you have disabled one or more cookies, we may still use information collected from cookies prior to your disabled preference being set, however, we will stop using the disabled cookie to collect any further information.</div>
              
              
                <div className="headermiddle_topsectiontxt">Updates to this Cookies Policy </div>
             
                <div className="headermiddle_subParagrapgh30">This Cookies Policy may be updated regularly as and when new cookies are implemented, or existing cookies removed. If necessary, we will draw the changes to your attention when you next visit the Site.</div>
                <div className="headermiddle_subParagrapgh31">We may also update this Cookie Policy based upon cookie best practices and legal requirements.</div>
                <div className="headermiddle_subParagrapgh32">You are encouraged to check back here in order to keep up-to-date with the latest version of this Cookie Policy.</div>
                <div className="headermiddle_subParagrapgh33">You may contact us at: privacy@semrush.com</div>
            </div>
        </div>
    )
}

export default CookiesPolicy














